import React, { useEffect, useState } from "react";
import * as s from "./styles/globalStyles";
import  { MintButton }  from "./mint/mintButton";

function App() {
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getConfig = async () => {
    // Change for presale and public sale
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <s.StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        <s.SpacerSmall />
        <s.ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            {/* <s.StyledImg alt={"example"} src={"/config/images/example.gif"} /> */}
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={3}
            jc={"start"}
            ai={"center"  }
            image={"/config/images/ckexp.png"}
            style={{
              // backgroundImage: {"/config/images/ckexp.png"},
              padding: 0
              // borderRadius: 12
              // border: "2px lined var(--secondary)",
              // boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
              
            }}
          >
          <s.SpacerLarge />
          <s.SpacerLarge />
          <MintButton/>
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            {/* <s.StyledImg
              alt={"example"}
              src={"/config/images/example.gif"}
              style={{ transform: "scaleX(-1)" }}
            /> */}
          </s.Container>
        </s.ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <a target={"_blank"} href={'https://nodeify.world/'}>
          <s.DStyledLogo alt={"nodeify"} src={"/config/images/nodeify.png"}/>
          </a>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
